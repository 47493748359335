<template>
  <b-container fluid>
    <b-overlay :show="pdfLoading">
        <b-row class="text-right">
            <b-col class="mb-2">
                <b-button variant="primary" @click="pdfExport(items.id)" class="mr-2">
                        {{  $t('globalTrans.print') }}
                </b-button>
            </b-col>
        </b-row>
       <b-row>
            <template>
                <b-row>
                    <b-col md="12">
                          <table class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 20%">{{ $t('globalTrans.fiscal_year')}}</th>
                                <td style="width: 30%">: {{ getFiscalYear(items.fiscal_year_id) }}</td>
                                <th style="width: 20%">{{ $t('globalTrans.month')}}</th>
                                <td style="width: 30%">: {{ getMonth(items.month) }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('allocation_management.allotment_count')}}</th>
                                <td style="width: 30%">: {{ $n(items.allotment_count_id, {useGrouping:false}) }}</td>
                                <th>{{ $t('allocation_dc_office.allotment_date') }}</th>
                                <td>: {{  items.allotment_date | dateFormat }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('allocation_dc_office.dispatch_no') }}</th>
                                <td>: {{ items.dispatch_no }}</td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
                 <b-row>
                  <b-col md="12">
                    <!-- &&&&&&&&&&&&&&Manual Info Details Start&&&&&&&&&&&&& -->
                        <div class="table-wrapper table-responsive tableFixHead">
                          <table class="table table-striped table-hover table-bordered">
                              <thead>
                                  <tr class="bg-primary">
                                      <th scope="col" style="width: 10%;"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                      <th scope="col" class="text-center"><div>{{ $t('allocation_management.regional_office') }}</div></th>
                                      <th scope="col" class="text-center"><div>{{ $t('globalTrans.district_name') }}</div></th>
                                      <th scope="col" class="text-center"><div>{{ $t('allocation_management.no_of_beneficary') }}</div></th>
                                       <template v-if="items.districts[0].details && items.districts[0].details.length > 0">
                                          <th  v-for="(item, index) in items.districts[0].details" :key="index">
                                            <div>{{ getItemCategoryName(item.item_category_id) }} ({{$t('allocation_dc_office.unit')}})</div>
                                          </th>
                                        </template>
                                  </tr>
                              </thead>
                              <tbody>
                                  <template v-if="items.districts && items.districts.length > 0">
                                      <template v-for="(region, regionIndex) in processedData" >
                                        <tr :key="regionIndex">
                                            <td class="text-center" :rowspan="region.rowspan+1">{{ $n(regionIndex + 1) }}</td>
                                            <td :rowspan="region.rowspan+1">{{ getRegionName(region.regionId) }}</td>
                                        </tr>
                                        <tr v-for="(item, itemIndex) in region.items" :key="itemIndex">
                                            <td class="text-center">{{ getDistrictName(item.district_id) }}</td>
                                            <td class="text-center">{{ $n(item.total_beneficiary) }}</td>
                                            <template v-if="item.details && item.details.length > 0">
                                            <td v-for="(detail, detailIndex) in item.details" :key="detailIndex" class="text-center">
                                                {{ $n(detail.allocated_quantity) }}
                                            </td>
                                            </template>
                                         </tr>
                                         <tr :key="regionIndex" class="font-weight-bold">
                                          <td></td>
                                          <td colspan="2" class="text-right ">{{$t('globalTrans.sub_total')}}</td>
                                          <td class=" text-center">{{$n(getSubTotalBeneficiary(region))}}</td>
                                           <td class="text-center" v-for="(item, index) in items.districts[0].details" :key="index">
                                            {{ $n(getSubTotalBeneficiaryItem(region,item.item_category_id)) }}
                                          </td>
                                        </tr>
                                      </template>
                                      <tr class="font-weight-bold">
                                          <td class="text-right" colspan="3"> {{$t('globalTrans.grand_total')}}:</td>
                                          <td class="text-center">{{ $n(getTotalBeneficiary()) }}</td>
                                          <template v-if="items.districts[0].details && items.districts[0].details.length > 0">
                                          <td class="text-center" v-for="(item, index) in items.districts[0].details" :key="index">
                                            {{ $n(getTotalBeneficiaryItem(item.item_category_id)) }}
                                          </td>
                                        </template>
                                      </tr>
                                  </template>
                                  <template v-else>
                                      <tr>
                                          <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                      </tr>
                                  </template>
                              </tbody>
                          </table>
                        </div>
                        <!-- &&&&&&&&&&&&&&Manual Info Details End&&&&&&&&&&&&&&& -->
                  </b-col>
                </b-row>
                <b-row v-if="items.remarks || items.remarks_bn">
                    <b-col md="12">
                          <table class="table table-sm table-borderless">
                            <tr>
                                <th >{{ $t('globalTrans.remark') }}</th>
                                <td colspan="3" class="text-center">
                                   {{ currentLocale == 'en' ? items.remarks : items.remarks_bn }}
                                </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
            </template>
        </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { allotmentPdf } from '../../api/routes'
export default {
    props: ['items'],
    components: {
    },
    created () {
      this.allotmentOrderData = this.items.details
    },
    mounted () {
        // core.index()
    },
    data () {
        return {
            pdfLoading: false,
            allotmentOrderData: [],
            licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        processedData () {
            let previousRegionId = null
            let rowspanCount = 0
            const processedData = []

            this.items.districts.filter(i => i.total_beneficiary > 0).forEach((item, index) => {
                if (index === 0 || item.regional_office_id !== previousRegionId) {
                rowspanCount = 1
                processedData.push({
                    rowspan: rowspanCount,
                    regionId: item.regional_office_id,
                    items: [item]
                })
                } else {
                rowspanCount++
                processedData[processedData.length - 1].rowspan = rowspanCount
                processedData[processedData.length - 1].items.push(item)
                }

                previousRegionId = item.regional_office_id
            })

            return processedData
        },
        monthList () {
            return this.$store.state.commonObj.monthList.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        getSubTotalBeneficiary (region) {
            return region.items.reduce((total, item) => total + parseFloat(item.total_beneficiary), 0)
        },
        getTotalBeneficiary () {
            return this.items.districts.reduce((total, item) => total + parseFloat(item.total_beneficiary), 0)
        },
        getSubTotalBeneficiaryItem (region, itemId) {
            let total = 0
            region.items.map((dsitrict, index) => {
                dsitrict.details.map((element, index2) => {
                    if (element.item_category_id === itemId) {
                        total += element.allocated_quantity
                    }
                })
            })
            return total
        },
        getTotalBeneficiaryItem (itemId) {
            let total = 0
            this.items.districts.map((dsitrict, index) => {
                dsitrict.details.map((element, index2) => {
                    if (element.item_category_id === itemId) {
                        total += element.allocated_quantity
                    }
                })
            })
            return total
        },
      getItemCategoryName (itemCategoryId) {
          const cateObj = this.$store.state.LicenseRegistrationService.commonObj.itemCategoryList.filter(item => item.status === 1).find(item => item.value === itemCategoryId)
          if (cateObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return cateObj.text_bn
              } else {
                  return cateObj.text_en
              }
          }
    },

     getFiscalYear (yearId) {
            const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
    getMonth (month) {
        const cateObj = this.monthList.find(item => item.value === parseInt(month))
        if (cateObj !== undefined) {
          return cateObj.text
        }
    },
       getDistrictName (id) {
            if (id) {
                const obj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
                if (this.$i18n.locale === 'bn') {
                    return obj.text_bn
                } else {
                    return obj.text_en
                }
            }
        },
     getRegionName (region, lang = this.$i18n.locale) {
        const data = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1).find(item => item.value === region)
        if (data !== undefined) {
          if (lang === 'bn') {
            return data.text_bn
          } else {
            return data.text_en
          }
        }
      },
        // pdfExport () {
        //     Pdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 2, this.items, this)
        // }
        async pdfExport (allotmentId) {
            this.pdfLoading = true
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, allotment_id: allotmentId })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, allotmentPdf, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
                })
                this.pdfLoading = false
                var url = window.URL.createObjectURL(blob)
                window.open(url).print()
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    }
}

</script>
<style scoped>
.tableFixHead          { overflow: auto; height: 500px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1;background-color: #77a0cb;}
</style>
